.forgot-password {
    &-wrapper {
        padding: 32px 20px;
    }

    &-content {
        width: 500px;

        .logo {
            margin-top: 0px;
        }
    }

    &-text {
        margin-top: 154px;

        h4 {
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            color: #F4730E;
            margin-bottom: 14px;
        }

        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.6);
        }
    }

    &-form {
        background-color: rgba(242, 242, 242, 0.4);
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-bottom: 11px;
        margin-top: 56px;
        .form-label {
            // width: 30%;
            padding-left: 20px;
            padding-right: 10px;
            margin: 0;

            label {
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                font-weight: 700;
                margin: 0;
            }
        }

        .form-input {
            padding: 20px 0;
            width: 70%;

            input {
                border: 0;
                outline: none;
                width: 100%;
                background-color: transparent;
            }
        }
    }
}

.form-button {
    text-align: center;

    .btn {
        background-color: #F4730E;
        color: #fff;
        text-align: center;
        width: 100%;
        display: block;
        border-radius: 12px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        display: block;
        height: 50px;

        &:hover {
            color: #fff;
            border-color: #F4730E;
        }

        &:focus {
            box-shadow: none;
            outline: none;
        }
    }

    .back {
        margin-top: 30px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #F4730E;
        text-decoration: none;
        display: block;
    }
}
@media screen and (max-width: 1100px) {
    .forgot-password-content {
        width: 100%;
    }
    .forgot-password-text {
        margin-top: 40px;
    }
}
@media screen and (max-width: 992px) {
    .hide {
        display: none;
    }
}
@media screen and (max-width: 575px) {
    .hide {
        display: none;
    }
    .forgot-password-form {
        display: block;
        .form-label, .form-input {
            padding: 10px;
        }
    }
    
}

.form-button {
    margin-top: 18px;
}

.successMsg {
    color: green !important;
}
.form-button .BorderNone {
     border: 0px !important;
}
.form-button .BorderNone:hover{
    background-color: #F4730E !important;
}